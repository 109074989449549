import { useEffect, useState } from "react"
import { useMutation } from "@apollo/client"
import { Formik, Form, Field } from "formik"
import * as Yup from "yup"

import { Box, Button, MenuItem, TextField, Typography, InputAdornment } from "@mui/material"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"

import styles from "./applicationSection.module.css"
import PhoneSMSVerification from "../PhoneSMSVerification/PhoneSMSVerification"
import { checkIDCard } from "../../utils/checkIDCard"
import { CREATE_APPLICATION_FORM, UPDATE_APPLICATION_FORM } from "../../graphql/ApplicationForm/APPLICATION_FORM_GQL"
import { useNavigate } from "react-router-dom"
import { Paths } from "../../utils/Paths"
import { createUser } from "../../signature/firebaseConf"
import moment from "moment"
import useSubmitForm from "../../hooks/useSubmitForm"
import useDeviceQueries from "../../hooks/useDeviceQueries"

const ApplicationSection = ({ onStepChange }) => {
  const { isDesktop, isTablet, isLaptop, isMobile, isDesktop2560, isDesktop1920, isDesktop1440 } = useDeviceQueries()
  const { submitForm } = useSubmitForm()
  const navigate = useNavigate()
  const [step, setStep] = useState(1)
  //usetate for flag sms verifaction status
  const [isSmsVerified, setIsSmsVerified] = useState(false)

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("請填寫姓名"),
    phone: Yup.string().required("請填寫手機號碼"),
    amount: Yup.number().typeError("貸款金額必須是數字").required("請填寫貸款金額").positive("請填寫正確貸款金額"),
    paymentPeriod: Yup.number().typeError("貸款金額必須是數字").required("請填寫還款期數").positive("請填寫正確還款期數"),
    paymentMethod: Yup.string().required("請選擇支薪方式"),

    salary: Yup.number().typeError("薪金收入必須是數字"),
    idNumber: Yup.string()
      .test("idNumber", "請填寫正確身份證號碼", (value) => checkIDCard(value))
      .required("請填寫身份證號碼"),
  })

  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    amount: "",
    paymentPeriod: "",
    paymentMethod: "",
    salary: "",
    comname: "",
    idNumber: "",
  })

  const [currentID, setCurrentID] = useState("")

  useEffect(() => {
    onStepChange(step)
  }, [step])

  const handleSubmit = async (values) => {
    try {
      const formattedValues = {
        ...values,
        finished: true,

        // workDuration: values.workDuration
        //   ? moment(values.workDuration).format("YYYY-MM-DD")
        //   : null,
      }

      const { data } = await updateApplicationForm({
        variables: { where: { id: currentID }, data: formattedValues },
      })

      // const crmValues = {
      //   business_date: values.workDuration
      //     ? moment(values.workDuration).format("YYYY-MM-DD")
      //     : null,
      //   mobile: values.phone,
      //   id_no: values.idNumber,
      //   cname: values.name,
      //   salary_pay_type: values.paymentMethod,
      //   period: values.paymentPeriod,
      //   addr: values.address,
      // }

      if (formattedValues) {
        const response = await submitForm(formattedValues) //submit to crm
      }

      const userData = {
        idCard: values.idNumber,
        password: values.phone,
        displayName: values.name,
        loanNo: values.idNumber,
      }
      await createUser(userData)
      //sumbit success
      navigate(Paths.thankyou)
    } catch (error) {
      //submit fail
      console.error("Error submitting form:", error)

      if (error.graphQLErrors) {
        error.graphQLErrors.forEach(({ message, locations, path }) =>
          console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
        )
      }

      if (error.networkError) {
        console.error(`[Network error]: ${error.networkError}`)
      }

      console.error(`[Error]: ${error.message}`)
    }
  }

  const handleNextStep = async (values) => {
    try {
      const formattedValues = {
        name: values.name,
        phone: values.phone,
      }
      setStep(2)

      //send data to cms and get reference number
      const { data } = await createApplicationForm({
        variables: { data: formattedValues },
      })
      if (data.createApplicationForm.id) {
        setCurrentID(data.createApplicationForm.id)
      }
    } catch (error) {
      console.error("Error submitting form:", error)
    }
  }

  const [createApplicationForm] = useMutation(CREATE_APPLICATION_FORM)
  const [updateApplicationForm] = useMutation(UPDATE_APPLICATION_FORM)
  // const [createApplicationFormRegister] = useMutation(
  //   CREATE_APPLICATION_FORM_REGISTER
  // )

  return (
    <Box className={styles.boxSection}>
      <Formik initialValues={formData} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({ isSubmitting, values, handleChange, handleBlur, setFieldValue, touched, errors, isValid }) => {
          const handleChangePhone = (phone) => {
            setFieldValue("phone", phone)
          }

          return (
            <Form
              className={styles.formContainer}
              // onSubmit={(e) => {
              //   e.preventDefault()
              //   handleSubmit(values)
              // }}
            >
              {step === 1 && (
                <>
                  <Field
                    as={TextField}
                    className={styles.customTextField}
                    label="中文全名(同身份證)"
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    margin="normal"
                    error={touched.name && !!errors.name}
                    helperText={touched.name && errors.name}
                  />

                  <PhoneSMSVerification onSuccess={setIsSmsVerified} setPhone={handleChangePhone} />
                </>
              )}
              {step >= 2 && (
                <>
                  <Typography variant={isMobile ? "h6" : "h4"} className="color6 mt-4 mb-4" fontWeight={"600"}>
                    請填寫以下信息以便完成批核
                  </Typography>
                  <div className={styles.formRow}>
                    <Field
                      as={TextField}
                      label="貸款金額"
                      required
                      className={`${styles.customTextField} ${styles.formField} me-3`}
                      name="amount"
                      value={values.amount}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      margin="normal"
                      error={touched.amount && !!errors.amount}
                      helperText={touched.amount && errors.amount}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <span
                              style={{
                                color: "#108d91",
                                zIndex: "99",
                                fontSize: "20px",
                              }}
                              onClick={() => document.getElementsByName("amount")[0].focus()}
                            >
                              HKD
                            </span>
                          </InputAdornment>
                        ),
                      }}
                    />

                    <Field
                      as={TextField}
                      className={`${styles.customTextField} ${styles.formField} ms-3`}
                      label="還款期數"
                      required
                      name="paymentPeriod"
                      value={values.paymentPeriod}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      margin="normal"
                      error={touched.paymentPeriod && !!errors.paymentPeriod}
                      helperText={touched.paymentPeriod && errors.paymentPeriod}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <span
                              style={{
                                color: "#108d91",
                                zIndex: "99",
                                fontSize: "20px",
                              }}
                              onClick={() => document.getElementsByName("paymentPeriod")[0].focus()}
                            >
                              Month
                            </span>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div className={styles.formRow}>
                    <Field
                      as={TextField}
                      className={`${styles.customTextField} ${styles.formField} me-3`}
                      required
                      select
                      label="支薪方式"
                      name="paymentMethod"
                      value={values.paymentMethod}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      margin="normal"
                      error={touched.paymentMethod && !!errors.paymentMethod}
                      helperText={touched.paymentMethod && errors.paymentMethod}
                    >
                      <MenuItem value="自動轉賬">自動轉賬</MenuItem>
                      <MenuItem value="支票">支票</MenuItem>
                      <MenuItem value="現金">現金</MenuItem>
                      <MenuItem value="現金票">現金票</MenuItem>
                      <MenuItem value="戶口過數">戶口過數</MenuItem>
                    </Field>
                    <Field
                      as={TextField}
                      label="薪金收入"
                      required
                      className={`${styles.customTextField} ${styles.formField} ms-3`}
                      name="salary"
                      value={values.salary}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      margin="normal"
                      error={touched.salary && !!errors.salary}
                      helperText={touched.salary && errors.salary}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <span
                              style={{
                                color: "#108d91",
                                zIndex: "99",
                                fontSize: "20px",
                              }}
                              onClick={() => document.getElementsByName("salary")[0].focus()}
                            >
                              HKD
                            </span>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div className={styles.formRow}>
                    <Field
                      as={TextField}
                      // className={styles.customTextField}
                      className={`${styles.customTextField} ${styles.formField} me-3`}
                      label="身份證號碼"
                      required
                      name="idNumber"
                      value={values.idNumber}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      margin="normal"
                      error={touched.idNumber && !!errors.idNumber}
                      helperText={touched.idNumber && errors.idNumber}
                    />
                    <Field
                      as={TextField}
                      className={`${styles.customTextField} ${styles.formField} ms-3`}
                      label="公司名稱"
                      name="comname"
                      value={values.comname}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      margin="normal"
                      error={touched.comname && !!errors.comname}
                      helperText={touched.comname && errors.comname}
                    />
                  </div>
                </>
              )}
              <div className={styles.buttonContainer}>
                {step === 1 ? (
                  <Button
                    className={styles.customButton}
                    variant="contained"
                    color="primary"
                    onClick={() => handleNextStep(values)}
                    disabled={isSubmitting || !isSmsVerified || !values.name}
                  >
                    下一步
                  </Button>
                ) : (
                  <Button className={styles.customButton} variant="contained" color="primary" type="submit" disabled={isSubmitting || !isValid}>
                    遞交申請
                  </Button>
                )}
              </div>
            </Form>
          )
        }}
      </Formik>
    </Box>
  )
}

export default ApplicationSection
