export function VideoPlayer({ videoRef }) {
  return (
    <video
      id="vd"
      ref={videoRef}
      muted
      playsInline
      autoPlay
      loop
      width="100%"
      height={window.innerHeight * 0.9}
      controls={false}
      style={{ border: "none", outline: "none" }}
    >
      <source src={"/promo_mob_video.mp4"} type="video/mp4" />
    </video>
  )
}
