export function VideoPlayerMobile({ videoRef }) {
  return (
    <video
      id="vd"
      ref={videoRef}
      muted
      playsInline
      autoPlay
      loop
      width="100%"
      // height={window.innerHeight * 1}
      controls={false}
      style={{ border: "none", outline: "none", clipPath: "polygon(0px 0%, 100% 0px, 100% 95%, 0px 100%)" }}
    >
      <source src={"/promo_mob_video.mp4"} type="video/mp4" />
    </video>
    // <video
    //   id="vd_mob"
    //   // ref={videoRef}
    //   muted
    //   playsInline
    //   autoplay
    //   loop
    //   // height={"100vh"}
    //   // width="auto"
    //   className="video_style"
    //   controls={false}
    //   style={{ border: "none", outline: "none", marginBottom: "-5px" }}
    // >
    //   <source src={"/promo_mob_video.mp4"} type="video/mp4" />
    // </video>
  )
}
